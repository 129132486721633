import * as types from "./actionTypes";

export const getCreditCard = (data) => {
  return {
    type: types.GET_CREDIT_CARD,
    payload: data,
  };
};

export const getCreditCardSuccess = (data) => {
    return {
      type: types.GET_CREDIT_CARD_SUCCESS,
      payload: data,
    };
  };