import Parse from "parse"
import { all, fork, put, takeLatest } from "redux-saga/effects"
import { GET_CREDIT_CARD,GET_CREDIT_CARD_SUCCESS } from "./actionTypes"
import { getCreditCardSuccess } from "./actions"
import { sagaErrorHandler } from "../../utils/SagaErrorHandler"

function* getCreditCardRequest({payload}) { 
  const {patientId, setCreditCard} =payload
    try {
      const creditCardInfo = yield Parse.Cloud.run("bookingPatientCardList",{patientId : patientId})
        setCreditCard(creditCardInfo)
      yield put(getCreditCardSuccess(creditCardInfo))
    } catch (error) {
      yield sagaErrorHandler(error)
    }
  }

  export function* watchGetCreditCard() {
    yield takeLatest(GET_CREDIT_CARD, getCreditCardRequest)
  }
  
  export default function* CreditCardSaga() {
    yield all([fork(watchGetCreditCard)])
  }
