import Parse from "parse";
import {
  REGISTER_PATIENT
} from "./actionTypes";
import { sagaErrorHandler } from "../../utils/SagaErrorHandler";
import { toast } from "react-toastify";

import { all, fork, takeLatest, put } from "redux-saga/effects";

function* createPatientRequest({ payload }) {
  const {
    firstName,
    lastName,
    gender,
    emailAddress,
    phone,
    dateOfBirth,
    postalCode,
    setDateOfBirth,
    city,
    state,
    setPhoneNumber,
    address1,
    location,
    generatedBy,
    lastUpdatedBy,
    setIsLoading,
    resetFields,
    setSelectedLocation,
    defaultSelectedLocationValue,
    setShowCreatePatientComponent
  } = payload;

  try {
    const patientResponse = yield Parse.Cloud.run("registerPatient", {
      firstName: firstName,
      lastName: lastName,
      gender: gender,
      emailAddress: emailAddress.toLowerCase(),
      phone: phone,
      dateOfBirth: dateOfBirth,
      postalCode: postalCode,
      address1: address1,
      city: city,
      state: state,
      preferredLocation: location,
      generatedBy: generatedBy,
      lastUpdatedBy: lastUpdatedBy
    });
    if (patientResponse) {
      setIsLoading(false);
      setDateOfBirth("");
      toast.success("Patient Created Successfullly");
      setSelectedLocation(defaultSelectedLocationValue);
      resetFields();
      setPhoneNumber("");
      setShowCreatePatientComponent(false);
    }
    setShowCreatePatientComponent(false)

  } catch (error) {
    setIsLoading(false);
    setShowCreatePatientComponent(false)
    yield sagaErrorHandler(error);
  }
}

export function* watchCreatePatient() {
  yield takeLatest(REGISTER_PATIENT, createPatientRequest);
}

export default function* PatientSaga() {
  yield all([
    fork(watchCreatePatient)
  ]);
}
