// export const mapBoxStores = {
//   type: "FeatureCollection",
//   features: [
//     {
//       type: "Feature",
//       geometry: {
//         type: "Point",
//         coordinates: [-122.4178026, 37.789371],
//       },
//       properties: {
//         id: "9000000000830",
//         phoneFormatted: "(415) 928-4844",
//         phone: "4159284844",
//         title: "Hyde Street",
//         address: "909 Hyde Stree Suite 406",
//         city: "San Francisco",
//         state: "CA",
//         postalCode: "94109",
//         country: "United States",
//       },
//     },
//     {
//       type: "Feature",
//       geometry: {
//         type: "Point",
//         coordinates: [-122.2646307, 37.8174696],
//       },
//       properties: {
//         id: "9000000000840",
//         phoneFormatted: "(510) 356-2100",
//         phone: "5103562100",
//         title: "Summit St",
//         address: "2844 Summit Street Suite 206",
//         city: "Oakland",
//         state: "CA",
//         postalCode: "94609",
//         country: "United States",
//       },
//     },
//     {
//       type: "Feature",
//       geometry: {
//         type: "Point",
//         coordinates: [-121.9719197, 37.7685119],
//       },
//       properties: {
//         id: "9000000000778",
//         phoneFormatted: "(925) 415-2206",
//         phone: "9254152206",
//         title: "San Ramon — Hygiene Bar",
//         address: "2610 San Ramon Valley Blvd",
//         city: "San Ramon",
//         state: "CA",
//         postalCode: "94583",
//         country: "United States",
//       },
//     },
//     {
//       type: "Feature",
//       geometry: {
//         type: "Point",
//         coordinates: [-122.131373, 37.9935958],
//       },
//       properties: {
//         id: "9000000000750",
//         phoneFormatted: "(925) 293-8956",
//         phone: "9252938956",
//         title: "Martinez — Hygiene Bar",
//         address: "4041 Alhambra Ave Suite 109",
//         city: "Martinez",
//         state: "CA",
//         postalCode: "94553",
//         country: "United States",
//       },
//     },
//     {
//       type: "Feature",
//       geometry: {
//         type: "Point",
//         coordinates: [-122.2907019, 37.8905488],
//       },
//       properties: {
//         id: "9000000000700",
//         phoneFormatted: "(510) 525-5510",
//         phone: "5105255510",
//         title: "Albany",
//         address: "1396 Solano Avenue",
//         city: "Albany",
//         state: "CA",
//         postalCode: "94706",
//         country: "United States",
//       },
//     },
//     {
//       type: "Feature",
//       geometry: {
//         type: "Point",
//         coordinates: [-122.2742583, 37.8008749],
//       },
//       properties: {
//         id: "9000000000623",
//         phoneFormatted: "(202) 234-7336",
//         phone: "2022347336",
//         title: "Old Oakland",
//         address: "827 Broadway Suite 320",
//         city: "Oakland",
//         state: "CA",
//         postalCode: "94607",
//         country: "United States",
//       },
//     },
//     {
//       type: "Feature",
//       geometry: {
//         type: "Point",
//         coordinates: [-122.2692812, 37.8043626],
//       },
//       properties: {
//         id: "9000000000645",
//         phoneFormatted: "(202) 507-8357",
//         phone: "2025078357",
//         title: "15th Street",
//         address: "363 15th Street",
//         city: "Oakland",
//         state: "CA",
//         postalCode: "94612",
//         country: "United States",
//       },
//     },
//     {
//       type: "Feature",
//       geometry: {
//         type: "Point",
//         coordinates: [-122.262081, 37.8209967],
//       },
//       properties: {
//         id: "9000000000661",
//         phoneFormatted: "(202) 387-9338",
//         phone: "2023879338",
//         title: "Pill Hill",
//         address: "3300 Webster Street Suite 812",
//         city: "Oakland",
//         state: "CA",
//         postalCode: "94609",
//         country: "United States",
//       },
//     },
//     {
//       type: "Feature",
//       geometry: {
//         type: "Point",
//         coordinates: [-122.2456626, 37.8186966],
//       },
//       properties: {
//         id: "9000000000743",
//         phoneFormatted: "(510) 654-3030",
//         phone: "5106543030",
//         title: "Grand Avenue",
//         address: "3931 Grand Avenue",
//         city: "Oakland",
//         state: "CA",
//         postalCode: "94610",
//         country: "United States",
//       },
//     },
//     {
//       type: "Feature",
//       geometry: {
//         type: "Point",
//         coordinates: [-122.2483022, 37.8308693],
//       },
//       properties: {
//         id: "9000000000635",
//         phoneFormatted: "(510) 654-5752",
//         phone: "5106545752",
//         title: "Pleasant Valley",
//         address: "1880 Pleasant Valley Avenue Suite F",
//         city: "Oakland",
//         state: "CA",
//         postalCode: "94611",
//         country: "United States",
//       },
//     },
//     {
//       type: "Feature",
//       geometry: {
//         type: "Point",
//         coordinates: [-122.2093167, 37.8261488],
//       },
//       properties: {
//         id: "9000000000660",
//         phoneFormatted: "(510) 390-8833",
//         phone: "5103908833",
//         title: "Montclair",
//         address: "6201 Antioch Street Suite 102",
//         city: "Oakland",
//         state: "CA",
//         postalCode: "94611",
//         country: "United States",
//       },
//     },
//     {
//       type: "Feature",
//       geometry: {
//         type: "Point",
//         coordinates: [-122.3027542, 37.8377053],
//       },
//       properties: {
//         id: "9000000000646",
//         phoneFormatted: "(510) 652-8855",
//         phone: "5106528855",
//         title: "Emeryville",
//         address: "E2334 Powell Street",
//         city: "Emeryville",
//         state: "CA",
//         postalCode: "94608",
//         country: "United States",
//       },
//     },
//     {
//       type: "Feature",
//       geometry: {
//         type: "Point",
//         coordinates: [-122.259954, 37.8555291],
//       },
//       properties: {
//         id: "9000000000636",
//         phoneFormatted: "(510) 849-6846",
//         phone: "5108496846",
//         title: "Telegraph",
//         address: "3017 Telegraph Avenue Suite 300",
//         city: "Berkeley",
//         state: "CA",
//         postalCode: "94705",
//         country: "United States",
//       },
//     },
//     {
//       type: "Feature",
//       geometry: {
//         type: "Point",
//         coordinates: [-122.261372, 37.8637309],
//       },
//       properties: {
//         id: "9000000000644",
//         phoneFormatted: "(510) 486-1813",
//         phone: "5104861813",
//         title: "Dana Street",
//         address: "2522 Dana Street Suite 101",
//         city: "Berkeley",
//         state: "CA",
//         postalCode: "94704",
//         country: "United States",
//       },
//     },
//     {
//       type: "Feature",
//       geometry: {
//         type: "Point",
//         coordinates: [-122.2751408, 37.7833574],
//       },
//       properties: {
//         id: "9000000000810",
//         phoneFormatted: "(510) 521-7784",
//         phone: "5105217784",
//         title: "Marina",
//         address: "809 Marina Village Pkwy",
//         city: "Alameda",
//         state: "CA",
//         postalCode: "94501",
//         country: "United States",
//       },
//     },
//     {
//       type: "Feature",
//       geometry: {
//         type: "Point",
//         coordinates: [-122.2600937, 37.8291312],
//       },
//       properties: {
//         id: "9000000000824",
//         phoneFormatted: "(510) 658-5483",
//         phone: "5106585483",
//         title: "Temescal",
//         address: "407 41st St",
//         city: "Oakland",
//         state: "CA",
//         postalCode: "94609",
//         country: "United States",
//       },
//     },
//   ],
// };

// export const mapBoxStoresWelcome = {
//   type: "FeatureCollection",
//   features: [
//     {
//       type: "Feature",
//       geometry: {
//         type: "Point",
//         coordinates: [-121.9719197, 37.7685119],
//       },
//       properties: {
//         id: "9000000000778",
//         phoneFormatted: "(925) 415-2206",
//         phone: "9254152206",
//         title: "San Ramon — Hygiene Bar",
//         address: "2610 San Ramon Valley Blvd",
//         city: "San Ramon",
//         state: "CA",
//         postalCode: "94583",
//         country: "United States",
//       },
//     },
//     {
//       type: "Feature",
//       geometry: {
//         type: "Point",
//         coordinates: [-122.131373, 37.9935958],
//       },
//       properties: {
//         id: "9000000000750",
//         phoneFormatted: "(925) 293-8956",
//         phone: "9252938956",
//         title: "Martinez — Hygiene Bar",
//         address: "4041 Alhambra Ave Suite 109",
//         city: "Martinez",
//         state: "CA",
//         postalCode: "94553",
//         country: "United States",
//       },
//     },
//     {
//       type: "Feature",
//       geometry: {
//         type: "Point",
//         coordinates: [-122.2603305, 37.8541701],
//       },
//       properties: {
//         id: "9000000000634",
//         phoneFormatted: "(510) 495-1075",
//         phone: "5104951075",
//         title: "Berkeley Welcome Center",
//         address: "3085 Telegraph Avenue",
//         city: "Berkeley",
//         state: "CA",
//         postalCode: "94705",
//         country: "United States",
//       },
//     },
//     {
//       type: "Feature",
//       geometry: {
//         type: "Point",
//         coordinates: [-122.2751408, 37.7833574],
//       },
//       properties: {
//         id: "9000000000810",
//         phoneFormatted: "(510) 521-7784",
//         phone: "5105217784",
//         title: "Marina",
//         address: "809 Marina Village Pkwy",
//         city: "Alameda",
//         state: "CA",
//         postalCode: "94501",
//         country: "United States",
//       },
//     },
//     {
//       type: "Feature",
//       geometry: {
//         type: "Point",
//         coordinates: [-122.3027542, 37.8377053],
//       },
//       properties: {
//         id: "9000000000646",
//         phoneFormatted: "(510) 652-8855",
//         phone: "5106528855",
//         title: "Emeryville",
//         address: "E2334 Powell Street",
//         city: "Emeryville",
//         state: "CA",
//         postalCode: "94608",
//         country: "United States",
//       },
//     },
//   ],
// };

export const locationGeo = {
  "9000000000623" : [-122.2742583, 37.8008749],
  "9000000000634" : [-122.2603305, 37.8541701],
  "9000000000635" : [-122.2483022, 37.8308693],
  "9000000000636" : [-122.259954, 37.8555291],
  "9000000000644" : [-122.261372, 37.8637309],
  "9000000000645" : [-122.2692812, 37.8043626],
  "9000000000646" : [-122.3027542, 37.8377053],
  "9000000000660" : [-122.2093167, 37.8261488],
  "9000000000661" : [-122.2517612, 37.827602],
  "9000000000700" : [-122.2907019, 37.8905488],
  "9000000000743" : [-122.2456626, 37.8186966],
  "9000000000750" : [-122.131373, 37.9935958],
  "9000000000778" : [-121.9719197, 37.7685119],
  "9000000000800" : [-122.262081, 37.8209967],
  "9000000000810" : [-122.2751408, 37.7833574],
  "9000000000821" : [-122.2606409, 37.829127],
  "9000000000824" : [-122.2606409, 37.829127],
  "9000000000830" : [-122.419585, 37.789376],
  "9000000000840" : [-122.2642842, 37.8175092],
  "9000000000842" : [-122.271726, 37.8057206],
  "9000000000843" : [-122.2646307, 37.8174696],
  "9000000000875" : [-122.2872229, 37.527001],
}

export const SKIPABLLE_LOCATIONS = ['9000000000842']

