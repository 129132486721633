import * as React from 'react';
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';

const MyPaymentForm = (props) => {
  const { isSquareProduction, saveCard, patient } = props
  const appId = isSquareProduction
    ? process.env.REACT_APP_SQUARE_APPLICATION_ID
    : process.env.REACT_APP_SQUARE_SANDBOX_APPLICATION_ID
  const locationId = isSquareProduction
    ? patient?.preferredLocation?.squareLocationId
    : patient?.preferredLocation?.squareSandboxLocationId

  return (
    <PaymentForm
      applicationId={appId}
      locationId={locationId}
      cardTokenizeResponseReceived={(token) => {
        try {
          saveCard(token)
        }
        catch (err) {
          console.log(err)
        }
      }}
    >
      <CreditCard
        render={(Button) => <Button style={{ backgroundColor: '#529638' }}>Add Card</Button>}
      />
    </PaymentForm>)
}

export default MyPaymentForm;