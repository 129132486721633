import produce from "immer";
import * as types from "./actionTypes";

const INITIAL_STATE = {
    creditCard: null
  };

const CreditCardReducer = produce((state, action) => {
    switch (action.type) {
      case types.GET_CREDIT_CARD_SUCCESS:
        state.creditCard = action.payload;
        break;
      default:
  
    }
  }, INITIAL_STATE);
  
export default CreditCardReducer;