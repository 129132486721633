import React, { useState } from "react"
import moment from "moment"
import { formatDate } from "../../utils/helpers"
import { useSelector } from "react-redux"
import PageTitle from "../../components/PageTitle"
import Button from "../../components/Form/Button"

const AppointmentDetails = ({ appointment, setCreatedAppointment }) => {
  const appointmentJSON = appointment?.toJSON()
  const associatedPatient = appointmentJSON?.patient;
  const associatedLocation = appointmentJSON?.location;

  const slotState = useSelector((state) => state?.Location.timeSloteAndLocation)

  return (
    <>
    <div className="mx-auto w-full max-w-3xl w-full px-4 py-12">
      <PageTitle title="Appointment Booked">
        The appointment has been booked.
      </PageTitle>
      <div className="rounded-2xl bg-white shadow-md border mt-5">
        <div className="px-6 py-6">
          <p className="text-sm md:text-xl font-medium mt-1 md:mt-2 text-mobile-grey-600 mb-4">
            Appointment details
          </p>
          <table className="table-auto mb-3">
            <tbody>
              <tr>
                <td className="p-1 pl-0 text-sm md:text-md mt-1 md:mt-2 text-mobile-grey-600">
                  <b>Patient name:</b>
                </td>
                <td className="p-1">
                  { `${associatedPatient?.firstName} ${associatedPatient?.lastName}`}
                </td>
              </tr>
              <tr>
                <td className="p-1 pl-0 text-sm md:text-md mt-1 md:mt-2 text-mobile-grey-600">
                  <b>Location:</b>
                </td>
                <td className="p-1">{associatedLocation?.name}</td>
              </tr>
              <tr>
                <td className="p-1 pl-0 text-sm md:text-md mt-1 md:mt-2 text-mobile-grey-600">
                  <b>Location address:</b>
                </td>
                <td className="p-1">
                  { associatedLocation?.address1 }
                  
                </td>
              </tr>
              <tr>
                <td className="p-1 pl-0 text-sm md:text-md mt-1 md:mt-2 text-mobile-grey-600">
                  <b>Date:</b>
                </td>
                <td className="p-1">
                {moment(slotState?.date).format("MMMM DD, YYYY")}
                </td>
              </tr>
              <tr>
                <td className="p-1 pl-0 text-sm md:text-md mt-1 md:mt-2 text-mobile-grey-600">
                  <b>Time slot:</b>
                </td>
                <td className="p-1 pl-0">
                {formatDate(slotState?.start, "h:mm a", "HH:mm")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
        <div className="text-center my-10">
          <Button
            className="py-2 px-10 shadow-md bg-[#62B144] hover:bg-[#529638] font-bold text-white rounded-full "
            onClick={() => setCreatedAppointment(null)}
          >
            Create another Appointment
          </Button>
        </div>
    </div>
    </>
  )
}

export default AppointmentDetails
