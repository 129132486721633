import Parse from "parse"
import { all, fork, takeLatest } from "redux-saga/effects"
import { ADD_FAMILY_MEMBER } from "./actionTypes"
import { sagaErrorHandler } from "../../utils/SagaErrorHandler"
import moment from "moment"
import { toast } from "react-toastify";

function* addFamilyMemberRequest({ payload }) {
  const { firstName, lastName, email, phone, dateOfBirth, gender, patientId, setAddFamilyMemberLoading, setFamilyMemberWarningModal, 
    setDateOfBirth,
    resetFields,
    setPhoneNumber,
    setShowCreatePatientComponent } = payload
    try {

    let data = {
      firstName: firstName,
      lastName: lastName,
      emailAddress: email,
      phone: phone,
      optionalParams: {
        dateOfBirth: moment(dateOfBirth).format("YYYY-MM-DD"),
        gender: gender,
        primaryGuarantorId: patientId,
        primaryContactId: patientId,
        isOwner: false,
      },
    }

    yield Parse.Cloud.run("addNewFamilyMember", data)
    setAddFamilyMemberLoading(false)
    toast.success('Family member added successfully')
    setFamilyMemberWarningModal(false)
    setDateOfBirth("");
    resetFields();
    setPhoneNumber("");
    setShowCreatePatientComponent(false)
  } catch (error) {
    setAddFamilyMemberLoading(false)
    yield sagaErrorHandler(error)
  }
}

export function* watchAddFamilyMember() {
  yield takeLatest(ADD_FAMILY_MEMBER, addFamilyMemberRequest)
}

export default function* FamilyMemberSaga() {
  yield all([fork(watchAddFamilyMember)])
}
