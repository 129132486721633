import Parse from "parse"
import { all, fork, put, takeLatest } from "redux-saga/effects"
import { GET_LOCATION } from "./actionTypes"
import { getLocationSuccess } from "./actions"
import { sagaErrorHandler } from "../../utils/SagaErrorHandler"

function* getLocationRequest({payload}) {
  try {
    const locationQuery = new Parse.Query("LocationV1").include("welcomeCenterLocation").equalTo("isActive", true)
      const locations = yield locationQuery.findAll()
      const sortedLocations = locations.map((loc) => loc).sort((a,b) => (a.get("name") > b.get("name")) ? 1 : -1 );
      yield put(getLocationSuccess(sortedLocations.map((location) => location.toJSON())))
  } catch (error) {
    yield sagaErrorHandler(error)
  } 
}

export function* watchGetLocation() {
  yield takeLatest(GET_LOCATION, getLocationRequest)
}

export default function* LocationSaga() {
  yield all([fork(watchGetLocation)])
}
