import { useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../../components/Form/Button";
import { ExclamationIcon } from "@heroicons/react/solid";

export const FamilyMemberWarningDialog = ({ familyMemberWarningModal, setFamilyMemberWarningModal, familyMember, handleCreateFamilyMember, addFamilyMemberLoading }) => {

  return (
    <>
      <Transition appear show={familyMemberWarningModal} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 overflow-y-auto z-50" onClose={() => setFamilyMemberWarningModal(false)}>
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:px-4 sm:p-0">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity overlay-dialog" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white dark:bg-black-700 rounded-lg space-y-3 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-xl sm:w-full p-7">
                <div className="sm:flex sm:flex-col">
                  <div className="flex-shrink-0 flex items-center justify-center h-10 mb-3 w-10 lg:h-14 lg:w-14 rounded-full bg-white-100 shadow-xl border border-1 border-gray-100 dark:bg-black-800 mx-auto ">
                    <ExclamationIcon className="h-6 w-6 text-red-600 dark:text-black-200" aria-hidden="true" />
                  </div>
                  <div className="text-center mt-5 sm:text-left">
                    <Dialog.Title as="h2" className="text-xl text-center leading-6 font-medium">
                      Patient Duplication
                    </Dialog.Title>
                    <div className="my-4 ">
                      <p className="text-md text-center px-4 mb-2">A patient <b>{`${familyMember?.patient?.get('patient')?.get('firstName') ?? ''} ${familyMember?.patient?.get('patient')?.get('lastName') ?? '' }`}</b> already exists with this <b>{ `${familyMember?.duplicateSource === 'both' ? 'email and phone' : familyMember?.duplicateSource }` }</b>.</p>
                      <p className="text-md text-center px-4 mb-5">Do you want to create new patient as a family member of existing patient ({`${familyMember?.patient?.get('patient')?.get('firstName') ?? ''} ${familyMember?.patient?.get('patient')?.get('lastName') ?? '' }`})?</p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-x-4 pt-4 border-t bottom-0 left-0 w-full bg-white items-center px-6 ">
                    <Button className="py-2 px-6 bg-gray-500 shadow-md hover:bg-gray-600 text-white rounded-full relative"
                        color="gray" variant="contained" onClick={() => setFamilyMemberWarningModal(false)}>
                        Cancel
                    </Button>
                    <Button className="py-2 px-6 shadow-md bg-[#62B144] hover:bg-[#529638] text-white rounded-full relative"
                        loading={addFamilyMemberLoading} variant="contained" onClick={handleCreateFamilyMember}>
                        Confirm
                    </Button>
                </div>
            </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
